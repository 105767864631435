<template>
	<div class="pages-body flex flex-column dark">

        <div class="topbar flex justify-content-between flex-row align-items-center dark">
            <router-link class="topbar-left flex ml-5" to="/">
                <div class="logo">
                    <img src="/layout/images/vaciegyhazmegye.png" alt="Váci Egyházmegye" style="height: 55px; margin-top: 4px;" />
                </div>
            </router-link>
            <router-link class="topbar-right mr-3 p-1 flex" to="/">
                <Button type="button" label="Írányítópult" class="p-button-text p-button-plain topbar-button"></Button>
            </router-link>
        </div>

        <div class="layout-content" style="padding: 2rem; padding-top: 1rem; flex: 1 1 0; background: grey;">
            <div class="grid dashboard">

                <div class="col-12 lg:col-2">
                    <div class="card height-100 flex flex-column" style="background: #FFF89B; color: #FFFFFF!important">
                        <div class="flex justify-content-center p-1 rounded-normal content-alt-bgcolor" style="height: 6rem;">
                            <img src="layout/images/intranet.png" alt="feature-faq" style="width:100%" >
                        </div>
                        <div class="flex flex-column align-items-center mt-3">
                            <h5 class="mb-2" style="color: #161616">Intranet</h5>
                            <p class="muted-text" style="color: #161616!important">A Váci Egyházmegye Intranet felülete.</p>
                        </div>
                        <div class="flex justify-content-between align-items-center pt-3 mt-auto">
                            <small class="muted-text" style="color: #161616!important">100% funkció</small>
                            <a class="topbar-right mr-3 flex" href="https://intranet.vaciegyhazmegye.hu" target="_blank">
                                <Button label="Átlépés"></Button>
                            </a>
                        </div>
                    </div>
                </div>    




                <div class="col-12 lg:col-2">
                    <div class="card height-100 flex flex-column" style="background: #D84315; color: white!important">
                        <div class="flex justify-content-center p-1 rounded-normal content-alt-bgcolor" style="height: 6rem;">
                            <img src="layout/images/sematizmus3.svg" alt="feature-faq" style="width:100%" >
                        </div>
                        <div class="flex flex-column align-items-center mt-3">
                            <h5 class="mb-2">Sematizmus - Admin</h5>
                            <p class="muted-text">A Váci Egyházmegye sematizmus admin felülete.</p>
                        </div>
                        <div class="flex justify-content-between align-items-center pt-3 mt-auto">
                            <small class="muted-text">10% funkció</small>
                            <a class="topbar-right mr-3 flex" :href="link('sematizmus', 'admin/', true)" target="_blank">
                                <Button label="Átlépés"></Button>
                            </a>
                        </div>
                    </div>
                </div>


                <div class="col-12 lg:col-2">
                    <div class="card height-100 flex flex-column" style="background: #ED2324; color: white!important">
                        <div class="flex justify-content-center p-1 rounded-normal content-alt-bgcolor" style="height: 6rem;">
                            <img id="logo" src="layout/images/LogoCaritas3K.svg" alt="Karitasz" style="width: 100%">
                        </div>
                        <div class="flex flex-column align-items-center mt-3">
                            <h5 class="mb-2">KARMEL - Admin</h5>
                            <p class="muted-text">
                                Karitász ügyféltámogatási elektronikai nyilvántartó rendszer
                            </p>
                        </div>
                        <div class="flex justify-content-between align-items-center pt-3 mt-auto">
                            <small class="muted-text">20% funkció</small>
                            <a class="topbar-right mr-3 flex" :href="link('karitasz', 'admin/', true)" target="_blank">
                                <Button label="Átlépés"></Button>
                            </a>
                        </div>
                    </div>
                </div>


                

                <div class="col-12 lg:col-2">
                    <div class="card height-100 flex flex-column" style="background: #EEEEEE; color: black!important">
                        <div class="flex justify-content-center p-1 rounded-normal content-alt-bgcolor" style="height: 6rem;">
                            <img src="layout/images/karitasz-adomany2.png" alt="feature-faq" style="width:100%" >
                        </div>
                        <div class="flex flex-column align-items-center mt-3">
                            <h5 class="mb-2" style="background: #EEEEEE; color: black!important">Karitász - Adomány</h5>
                            <p class="muted-text" style="background: #EEEEEE; color: black!important">A Váci Egyházmegye Karitász adomány felülete.</p>
                        </div>
                        <div class="flex justify-content-between align-items-center pt-3 mt-auto">
                            <small class="muted-text">20% funkció</small>
                            <a class="topbar-right mr-3 flex" :href="link('https://adomany.vaccaritas.hu/', '', false)" target="_blank">
                                <Button label="Átlépés"></Button>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-12 lg:col-2">
                    <div class="card height-100 flex flex-column" style="background: #00796B; color: white!important">
                        <div class="flex justify-content-center p-1 rounded-normal content-alt-bgcolor" style="height: 6rem;">
                            <img src="layout/images/gazdasagi.png" alt="feature-faq" style="width:100%" >
                        </div>
                        <div class="flex flex-column align-items-center mt-3">
                            <h5 class="mb-2">Gazdasági Hivatal - Admin</h5>
                            <p class="muted-text">A Váci Egyházmegye Gazdasági Hivatal Admin felülete.</p>
                        </div>
                        <div class="flex justify-content-between align-items-center pt-3 mt-auto">
                            <small class="muted-text">20% funkció</small>
                            <a class="topbar-right mr-3 flex" :href="link('gazdasagi', 'admin/', true)" target="_blank">
                                <Button label="Átlépés"></Button>
                            </a>
                        </div>
                    </div>
                </div>
                
                <div class="col-12 lg:col-2">
                    <div class="card height-100 flex flex-column" style="background: #1565C0; color: white!important">
                        <div class="flex justify-content-center p-1 rounded-normal content-alt-bgcolor" style="height: 6rem;">
                            <img src="layout/images/hitoktatas-admin.png" alt="feature-faq" style="width:100%" >
                        </div>
                        <div class="flex flex-column align-items-center mt-3">
                            <h5 class="mb-2">Hitoktatás - Admin</h5>
                            <p class="muted-text">A Váci Egyházmegye Hitoktatás admin felülete.</p>
                        </div>
                        <div class="flex justify-content-between align-items-center pt-3 mt-auto">
                            <small class="muted-text">30% funkció</small>
                            <a class="topbar-right mr-3 flex" :href="link('hitoktatas', 'admin/', true)" target="_blank">
                                <Button label="Átlépés"></Button>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-12 lg:col-2">
                    <div class="card height-100 flex flex-column" style="background: #5D4037; color: white!important">
                        <div class="flex justify-content-center p-1 rounded-normal content-alt-bgcolor" style="height: 6rem;">
                            <img src="layout/images/it.png" alt="feature-faq" style="width:100%" >
                        </div>
                        <div class="flex flex-column align-items-center mt-3">
                            <h5 class="mb-2">IT - Admin</h5>
                            <p class="muted-text">A Váci Egyházmegye IT adminisztrációs felülete.</p>
                        </div>
                        <div class="flex justify-content-between align-items-center pt-3 mt-auto">
                            <small class="muted-text">30% funkció</small>
                            <a class="topbar-right mr-3 flex" :href="link('it', 'admin/', true)" target="_blank">
                                <Button label="Átlépés"></Button>
                            </a>
                        </div>
                    </div>
                </div>


                <div class="col-12 lg:col-2">
                    <div class="card height-100 flex flex-column" style="background: #607D8B; color: white!important">
                        <div class="flex justify-content-center p-1 rounded-normal content-alt-bgcolor" style="height: 6rem;">
                            <img src="layout/images/temeto.png" alt="feature-faq" style="width:100%" >
                        </div>
                        <div class="flex flex-column align-items-center mt-3">
                            <h5 class="mb-2">Temető - Admin</h5>
                            <p class="muted-text">A Váci Egyházmegye Temető nyilvántartó adminisztrációs felülete.</p>
                        </div>
                        <div class="flex justify-content-between align-items-center pt-3 mt-auto">
                            <small class="muted-text">30% funkció</small>
                            <a class="topbar-right mr-3 flex" :href="link('temeto', 'admin/', true)" target="_blank">
                                <Button label="Átlépés"></Button>
                            </a>
                        </div>
                    </div>
                </div>                

                <div class="col-12 lg:col-2">
                    <div class="card height-100 flex flex-column" style="background: #FBC02D; color: #161616!important">
                        <div class="flex justify-content-center p-1 rounded-normal content-alt-bgcolor" style="height: 6rem;">
                            <img src="layout/images/konyvtar.png" alt="feature-faq" style="width:100%" >
                        </div>
                        <div class="flex flex-column align-items-center mt-3">
                            <h5 class="mb-2">Könyvtár - Admin</h5>
                            <p class="muted-text" style="background: #FBC02D; color: #161616!important">A Váci Egyházmegye Könyvtár támogató rendszer adminisztrációs felülete.</p>
                        </div>
                        <div class="flex justify-content-between align-items-center pt-3 mt-auto">
                            <small class="muted-text" style="background: #FBC02D; color: #161616!important">30% funkció</small>
                            <a class="topbar-right mr-3 flex" :href="link('konyvtar', 'admin/', true)" target="_blank">
                                <Button label="Átlépés"></Button>
                            </a>
                        </div>
                    </div>
                </div>                

                <div class="col-12 lg:col-2">
                    <div class="card height-100 flex flex-column" style="background: #0097A7; color: #FFFFFF!important">
                        <div class="flex justify-content-center p-1 rounded-normal content-alt-bgcolor" style="height: 6rem;">
                            <img src="layout/images/muzeum.png" alt="feature-faq" style="width:100%" >
                        </div>
                        <div class="flex flex-column align-items-center mt-3">
                            <h5 class="mb-2">Múzeum - Admin</h5>
                            <p class="muted-text" style="color: #161616!important">A Váci Egyházmegye Múzeum támogató rendszer adminisztrációs felülete.</p>
                        </div>
                        <div class="flex justify-content-between align-items-center pt-3 mt-auto">
                            <small class="muted-text" style="color: #161616!important">30% funkció</small>
                            <a class="topbar-right mr-3 flex" :href="link('muzeum', 'admin/', true)" target="_blank">
                                <Button label="Átlépés"></Button>
                            </a>
                        </div>
                    </div>
                </div>                

                <div class="col-12 lg:col-2">
                    <div class="card height-100 flex flex-column" style="background: #0288D1; color: #FFFFFF!important">
                        <div class="flex justify-content-center p-1 rounded-normal content-alt-bgcolor" style="height: 6rem;">
                            <img src="layout/images/hr.png" alt="feature-faq" style="width:100%" >
                        </div>
                        <div class="flex flex-column align-items-center mt-3">
                            <h5 class="mb-2">HR - Admin</h5>
                            <p class="muted-text">A Váci Egyházmegye HR támogató rendszer adminisztrációs felülete.</p>
                        </div>
                        <div class="flex justify-content-between align-items-center pt-3 mt-auto">
                            <small class="muted-text">30% funkció</small>
                            <a class="topbar-right mr-3 flex" :href="link('hr', 'admin/', true)" target="_blank">
                                <Button label="Átlépés"></Button>
                            </a>
                        </div>
                    </div>
                </div>         

                <div class="col-12 lg:col-2">
                    <div class="card height-100 flex flex-column" style="background: #6A1B9A; color: #FFFFFF!important">
                        <div class="flex justify-content-center p-1 rounded-normal content-alt-bgcolor" style="height: 6rem;">
                            <img src="layout/images/kerdoiv.png" alt="feature-faq" style="width:100%" >
                        </div>
                        <div class="flex flex-column align-items-center mt-3">
                            <h5 class="mb-2">Kérdőív - Admin</h5>
                            <p class="muted-text">A Váci Egyházmegye Kérdőív rendszer adminisztrációs felülete.</p>
                        </div>
                        <div class="flex justify-content-between align-items-center pt-3 mt-auto">
                            <small class="muted-text">30% funkció</small>
                            <a class="topbar-right mr-3 flex" href="https://kerdoiv.vaciegyhazmegye.hu/admin/" target="_blank">
                                <Button label="Átlépés"></Button>
                            </a>
                        </div>
                    </div>
                </div>    


                <div class="col-12 lg:col-2">
                    <div class="card height-100 flex flex-column" style="background: #37474F; color: #FFFFFF!important">
                        <div class="flex justify-content-center p-1 rounded-normal content-alt-bgcolor" style="height: 6rem;">
                            <img src="layout/images/ingatlan.png" alt="feature-faq" style="width:100%" >
                        </div>
                        <div class="flex flex-column align-items-center mt-3">
                            <h5 class="mb-2">Ingatlan - Admin</h5>
                            <p class="muted-text">A Váci Egyházmegye Ingatlan rendszer adminisztrációs felülete.</p>
                        </div>
                        <div class="flex justify-content-between align-items-center pt-3 mt-auto">
                            <small class="muted-text">30% funkció</small>
                            <a class="topbar-right mr-3 flex" :href="link('ingatlan', 'admin/', true)" target="_blank">
                                <Button label="Átlépés"></Button>
                            </a>
                        </div>
                    </div>
                </div>    


                <div class="col-12 lg:col-2">
                    <div class="card height-100 flex flex-column" style="background: #FFA000; color: #FFFFFF!important">
                        <div class="flex justify-content-center p-1 rounded-normal content-alt-bgcolor" style="height: 6rem;">
                            <img src="layout/images/szolgaltatasikozpont.png" alt="feature-faq" style="width:100%" >
                        </div>
                        <div class="flex flex-column align-items-center mt-3">
                            <h5 class="mb-2">Szolgáltatási Központ - Admin</h5>
                            <p class="muted-text" style="color: #161616!important">A Váci Egyházmegye Szolgáltatási Központ rendszer adminisztrációs felülete.</p>
                        </div>
                        <div class="flex justify-content-between align-items-center pt-3 mt-auto">
                            <small class="muted-text" style="color: #161616!important">80% funkció</small>
                            <a class="topbar-right mr-3 flex" :href="link('szolgaltatasikozpont', 'admin/', true)" target="_blank">
                                <Button label="Átlépés"></Button>
                            </a>
                        </div>
                    </div>
                </div>    

                <div class="col-12 lg:col-2">
                    <div class="card height-100 flex flex-column" style="background: #918477; color: #FFFFFF!important">
                        <div class="flex justify-content-center p-1 rounded-normal content-alt-bgcolor" style="height: 6rem;">
                            <img src="layout/images/korhazlelkeszi_szolgalat.png" alt="feature-faq" style="width:100%" >
                        </div>
                        <div class="flex flex-column align-items-center mt-3">
                            <h5 class="mb-2 context-center" style="text-align: center">Váci Egyházmegye<br />Kórházlelkészi Szolgálata</h5>
                            <p class="muted-text" style="color: #161616!important">A Váci Egyházmegye Kórházlelkészi Szolgálat felülete.</p>
                        </div>
                        <div class="flex justify-content-between align-items-center pt-3 mt-auto">
                            <small class="muted-text" style="color: #161616!important">10% funkció</small>
                            <a class="topbar-right mr-3 flex" href="https://korhazlelkesz.hu/" target="_blank">
                                <Button label="Átlépés"></Button>
                            </a>
                        </div>
                    </div>
                </div>    

                <div class="col-12 lg:col-2">
                    <div class="card height-100 flex flex-column" style="background: #2F3947; color: #FFFFFF!important">
                        <div class="flex justify-content-center p-1 rounded-normal content-alt-bgcolor" style="height: 6rem;">
                            <img src="layout/images/ingatlan.png" alt="feature-faq" style="width:100%" >
                        </div>
                        <div class="flex flex-column align-items-center mt-3">
                            <h5 class="mb-2 context-center" style="text-align: center">Váci Egyházmegye<br />Vallásszociológia</h5>
                            <p class="muted-text">A Váci Egyházmegye Vallásszociológia adatvizualizációs felülete. (2020. évi adattal bezárólag)</p>
                        </div>
                        <div class="flex justify-content-between align-items-center pt-3 mt-auto">
                            <small class="muted-text">100% funkció</small>
                            <a class="topbar-right mr-3 flex" href="https://vallasszociologia.vaciegyhazmegye.hu/" target="_blank">
                                <Button label="Átlépés"></Button>
                            </a>
                        </div>
                    </div>
                </div>    


            </div>
        </div>
        <div class="layout-content" style="padding: 2rem; padding-top: 1rem; flex: 1 1 0; background: darkgrey;">
        </div>

    </div>
</template>

<script>
	export default {
		data() {
			return {
				username: '',
				password: ''
			}
		},
        methods: {
            link(info, admin, igen) {
                if (! igen) {
                    return info
                } else {
                    return window.location.protocol + "//" +info + "." + window.location.host + "/" + admin;
                }
            }
        }
	}
</script>

<style scoped>
.pages-body .topbar {
    background: black;
}

div.p-1 {
    padding: 0px!important;
    background: #888888;
    border: solid 2px #888888;
    border-radius: 10px;
}
</style>
