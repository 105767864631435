<template>
    <Home />
</template>

<script>
    import Home from './pages/Home';

	export default {
        data() {
            return {
                theme: 'brown',
                layoutMode: 'dark',
                topbarTheme: 'brown',
                menuTheme: 'dark',
            }
        },
        methods: {
            onLayoutChange(layout) {
                this.layoutMode = layout;
                this.$appState.layoutMode = layout;
                this.menuTheme = layout;

                const logo = document.getElementById('logo');
                logo.src = 'layout/images/logo-light.svg';
                
                if (layout === 'dark') {
                    this.topbarTheme = 'dark';
                }
                else {
                    this.topbarTheme = 'blue';
                }
            },
            onTopbarThemeChange(theme) {
                this.topbarTheme = theme.name;

                const themeName = theme.name;
                const logo = document.getElementById('logo');

                if (themeName == 'white' || themeName == 'yellow' || themeName == 'amber'  || themeName == 'orange' || themeName == 'lime') {
                    logo.src = 'layout/images/logo-dark.svg';
                }
                else {
                    logo.src = 'layout/images/logo-light.svg';
                }
            },
            onMenuTheme(menuTheme) {
                this.menuTheme = menuTheme.name;
            }
        },
        components: {
            "Home": Home,
        }
	}
</script>

<style scoped>
</style>
